<template>
  <div style="width: 100%; height: 100%;">
    <div id="malaysia" style="width: 100%; height: 100%;"></div>
  </div>
</template>

<script>
  import * as echarts from "echarts";

  let malaysia = require("@/assets/json/malaysia.json");

  export default {
    name: "MalaysiaEcharts",
    data() {
      return {
        chart: {},
        dataList: [],
        maxSize: 0,
        perlis_v: 0,
        kedah_v: 0,
        perak_v: 0,
        kelantan_v: 0,
        terengganu_v: 0,
        pahang_v: 0,
        malacca_v: 0,
        johor_v: 0,
        sabah_v: 0,
        labuan_v: 0,
        sarawak_v: 0,
        negeri_Sembilan_v: 0,
        selangor_v: 0,
        kuala_Lumpur_v: 0,
        putrajaya_v: 0,
        pulau_Penang_v: 0,
      };
    },
    mounted() {
      this.getElevatorCount();
      this.initChart();
    },
    methods: {
      getElevatorCount() {
        this.$api.getData("elevators/countMalaysia").then(res => {
          this.elevatorCountData = res.data;
          for (let i = 0; i < this.elevatorCountData.length; i++) {
            if (this.elevatorCountData[i].name === "Perlis") {
              this.perlis_v = this.elevatorCountData[i].value;
            } else if (this.elevatorCountData[i].name === "Kedah") {
              this.kedah_v = this.elevatorCountData[i].value;
            } else if (this.elevatorCountData[i].name === "Perak") {
              this.perak_v = this.elevatorCountData[i].value;
            } else if (this.elevatorCountData[i].name === "Kelantan") {
              this.kelantan_v = this.elevatorCountData[i].value;
            } else if (this.elevatorCountData[i].name === "Terengganu") {
              this.terengganu_v = this.elevatorCountData[i].value;
            } else if (this.elevatorCountData[i].name === "Pahang") {
              this.pahang_v = this.elevatorCountData[i].value;
            } else if (this.elevatorCountData[i].name === "Malacca") {
              this.malacca_v = this.elevatorCountData[i].value;
            } else if (this.elevatorCountData[i].name === "Johor") {
              this.johor_v = this.elevatorCountData[i].value;
            } else if (this.elevatorCountData[i].name === "Sabah") {
              this.sabah_v = this.elevatorCountData[i].value;
            } else if (this.elevatorCountData[i].name === "Labuan") {
              this.labuan_v = this.elevatorCountData[i].value;
            } else if (this.elevatorCountData[i].name === "Sarawak") {
              this.sarawak_v = this.elevatorCountData[i].value;
            } else if (this.elevatorCountData[i].name === "Negeri Sembilan") {
              this.negeri_Sembilan_v = this.elevatorCountData[i].value;
            } else if (this.elevatorCountData[i].name === "Selangor") {
              this.selangor_v = this.elevatorCountData[i].value;
            } else if (this.elevatorCountData[i].name === "Kuala Lumpur") {
              this.kuala_Lumpur_v = this.elevatorCountData[i].value;
            } else if (this.elevatorCountData[i].name === "Putrajaya") {
              this.putrajaya_v = this.elevatorCountData[i].value;
            } else if (this.elevatorCountData[i].name === "Pulau Penang") {
              this.pulau_Penang_v = this.elevatorCountData[i].value;
            }
          }
          this.dataList = [
            {name: "Perlis", value: this.perlis_v},
            {name: "Kedah", value: this.kedah_v},
            {name: "Perak", value: this.perak_v},
            {name: "Kelantan", value: this.kelantan_v},
            {name: "Terengganu", value: this.terengganu_v},
            {name: "Pahang", value: this.pahang_v},
            {name: "Malacca", value: this.malacca_v},
            {name: "Johor", value: this.johor_v},
            {name: "Sabah", value: this.sabah_v},
            {name: "Labuan", value: this.labuan_v},
            {name: "Sarawak", value: this.sarawak_v},
            {name: "Negeri Sembilan", value: this.negeri_Sembilan_v},
            {name: "Selangor", value: this.selangor_v + this.kuala_Lumpur_v + this.putrajaya_v},
            {name: "Pulau Penang", value: this.pulau_Penang_v},
          ];
          for (let i = 0; i < this.dataList.length; i++) {
            if (this.dataList[i].value > this.maxSize) {
              this.maxSize = this.dataList[i].value;
            }
          }
          this.chart.setOption(this.getChartOption());
        }).catch(error => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      initChart() {
        echarts.registerMap("malaysia", malaysia);
        this.chart = echarts.init(document.getElementById("malaysia"));
        window.onresize = this.chart.resize;
        this.dataList = [
          {name: "Perlis", value: this.perlis_v},
          {name: "Kedah", value: this.kedah_v},
          {name: "Perak", value: this.perak_v},
          {name: "Kelantan", value: this.kelantan_v},
          {name: "Terengganu", value: this.terengganu_v},
          {name: "Pahang", value: this.pahang_v},
          {name: "Malacca", value: this.malacca_v},
          {name: "Johor", value: this.johor_v},
          {name: "Sabah", value: this.sabah_v},
          {name: "Labuan", value: this.labuan_v},
          {name: "Sarawak", value: this.sarawak_v},
          {name: "Negeri Sembilan", value: this.negeri_Sembilan_v},
          {name: "Selangor", value: this.selangor_v},
          {name: "Pulau Penang", value: this.pulau_Penang_v},
        ];
        this.chart.setOption(this.getChartOption());
      },
      getChartOption() {
        return {
          tooltip: {
            formatter: function(params, ticket, callback) {
              return params.seriesName + "<br />" + params.name + "：" + params.value;
            },
          },
          visualMap: {
            min: 0,
            max: this.maxSize,
            left: "left",
            top: "bottom",
            text: ["High", "low"],
            inRange: {
              color: ["#e0ffff", "#006edd"],
            },
            show: true,
          },
          geo: {
            map: "malaysia",
            roam: false,
            zoom: 1.23,
            label: {
              normal: {
                show: true,
                fontSize: "10",
                color: "rgba(0,0,0,0.7)",
              },
            },
            itemStyle: {
              normal: {
                borderColor: "rgba(0, 0, 0, 0.2)",
              },
              emphasis: {
                areaColor: "#F3B329",
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 20,
                borderWidth: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
          series: [
            {
              name: "number",
              type: "map",
              geoIndex: 0,
              data: this.dataList,
            },
          ],
        };
      },
    },
  };
</script>
